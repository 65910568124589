import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import {
  Container,
  Col,
  Title,
  Rating,
  Street,
  OpeningHoursContainer,
  OpeningHours,
  OptionsToggle,
  OptionContainer,
  Triangle,
  Option,
} from './LocationInfo.styled';

class LocationInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionsVisible: false,
    };
  }

  handleOptionsToggle = () => {
    this.setState({ optionsVisible: !this.state.optionsVisible });
  };

  handleDeleteLocation = () => {
    const { authorization, locationId, deleteLocation, history } = this.props;

    deleteLocation(authorization, locationId, () => history.push('/locations'));
  };

  handleUpdateLocation = () => {
    const { data, history } = this.props;

    if (!data) { return false; }

    history.push(`/locations/${data.id}/settings`);

    this.handleOptionsToggle();
  };

  render() {
    const {data, t} = this.props;
    const {optionsVisible} = this.state;

    if (data === undefined) {
      return null;
    }

    return (
      <Container>
        <Col>
          <Title>
            {data.name}
            <Rating><i className="fa fa-star" /> {(data.rating % 1 > 0) ? data.rating.toFixed(1) : data.rating}</Rating>
          </Title>
          <Street>{data.city}, {data.street}</Street>
          <Street>Кешбэк: {data.cashback_percent > 0 ? `${data.cashback_percent}%` : 'отсутствует'}</Street>
          <Street>Плата за простой: {data.free_parking ? 'отсутствует' : `есть`}</Street>
        </Col>
        <Col>
          <Title>{t('openingHours')}</Title>
          {data.opening_hours && data.opening_hours.periods &&
            <OpeningHoursContainer>
              {openingHourItems(data.opening_hours.periods)}
            </OpeningHoursContainer>
          }
          <OptionsToggle onClick={this.handleOptionsToggle}>
            <i className="fa fa-ellipsis-v" />
          </OptionsToggle>
          <OptionContainer visible={optionsVisible}>
            <Triangle />
            <Option onClick={this.handleUpdateLocation}>
              <i className="fa fa-cog" /> {t('settings')}
            </Option>
            <Option onClick={this.handleDeleteLocation}>
              <i className="fa fa-trash" /> {t('delete')}
            </Option>
          </OptionContainer>
        </Col>
      </Container>
    );
  }
}

const openingHourItems = periods => {
  const items = [];
  const weekdayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  for (const i in periods) {
    const {day, opens_at, closes_at} = periods[i];

    items.push(
      <OpeningHours key={i}>
        {weekdayNames[day]}:{' '}
        <span className="hours">
          {opens_at.substring(0, 2)}:{opens_at.substring(2)}-
          {closes_at.substring(0, 2)}:{closes_at.substring(2)}
        </span>
      </OpeningHours>
    );
  }

  return items;
};

export default compose(withTranslation(), withRouter)(LocationInfoContainer);
